


/*Boostrap overrides*/

.container-fluid.portfolio-page-main-div{
    padding-left: 0;
    padding-right: 0;
}



/*MY styles*/
.portfolio-page-main-div {
    background-color: #e5e6e5;
    padding: 30px;
    height: auto;
  }

.portfolio-app-row{
    padding-top: 30px;
    padding-bottom: 30px;

}
  .portfolioDivHead{
    padding: 50px;
    
  }

  .portfolioDivHead h5{
    padding: 10px 0px;
    line-height: 170%;
  }
  
  .portfolioDivHead ul{
    padding-left: 0;
    list-style-position: inside;
  }

  .portfolioDivHead li{
    padding: 5px;
  }

  .portfolioDivHead p{
    padding: 20px;
    line-height: 200%;
  }

  .portfolioDivHead  p.repourl{
    padding: 0;
    line-height: 200%;
  }


  .app-info {
    padding: 20px;
  }

  .app-pic:hover{
    border: 5px solid rgba(194, 194, 194, 0.616);
  }
  
  .app-pic {
    max-height: 100%;
    width: 100%;
    min-width: 200px;
    max-width: 300px;
    border-radius: 10px;
    
  }

  .explaination_image {
    max-height: 100%;
    width: 100%;
    min-width: 40vw;
    max-width: 80vw;
    border-radius: 5px;
  }

  .mybadge, .budgetDemoIcon{
    max-height: 100%;
    width: 100%;
    min-width: 60px;
    max-width: 75px;
  }

  .BilliardReplayVideoDemo{
    border: none;
    width: 100%;
    /* width: 80%; */
    height: 50vw;
    
}

.CustomVisionDemoGIF{
  border: none;
  width: 100%;
  height: 100vh;
  max-width: 806px;
  max-height: 385px;
}

.DivCustomVisionDemoGIF{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
}

.DivBilliardReplayVideoDemo {
    margin: 30px 0px;
}



  

  /*for tablets or smaller*/
  @media (max-width: 768px) {
    .portfolio-page-main-div {
      background-color: #e5e6e5;
      padding: 30px;
      height: auto;
    }

    .portfolioDivHead{
      padding: 40px;
      text-align: center;
    }
  
    .app-pic {
        margin-left: 50px;
        
      }
    /* .explaination_image {
        margin-left: 50px;
        
      } */

      .app-info {
        padding: 50px;
      }
  }


  .technology-used{
    font-weight:bold;
    
  }


/*Buttons oneach app page for portfolio page*/

button:focus {

  outline: none
}

  .portfolio-app-buttons{
    padding-bottom: 20px;

  }

  .portfolio-app-buttons .description{
    display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  color:rgba(78, 80, 88, 0.400);
  border: 2px solid #4E5058;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;


  }

 

  .portfolio-app-buttons .tech{
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color:rgba(78, 80, 88, 0.400);
    border: 2px solid #4E5058;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .portfolio-app-buttons .description.active{
    border-right: none;
  }

  .portfolio-app-buttons .tech.active{
    border-left: none;
  }

  .portfolio-app-buttons .active{
    color:#FFF;
    background-color: #4E5058;

  }

  .portfolio-app-buttons :not(.active):hover{
    color:rgba(255, 255, 255, 0.7);
    background-color: rgba(78, 80, 88, 0.4);

  }

  @media (max-width: 480px) {
    
    .portfolio-app-buttons .description{
      display: block;
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0px;
      width: 140px;
  
    }
  
    .portfolio-app-buttons .tech{
      display: block;
      text-align: center;
      border-top-right-radius:  0px;
     
      border-bottom-left-radius: 0.25rem;
      width: 140px;
    }

  }

  .keyFeatures{
    padding-left: 0;
    list-style-position: inside;
  }

  .keyFeatures li{
    padding: 2px 0px;
  }

  ul.raspberrypi_list li{
    list-style-type: none;
  }